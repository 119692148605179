import React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Overview from "../components/Overview";
import Features from "../components/Features";
import CTA from "../components/CTA";
import Pricing from "../components/Pricing";
import { Helmet } from "react-helmet";
import GdprConsent from "../components/GdprConsent";

function IndexPage() {
  return (
    <>
      <Layout bgImage="linear-gradient(135deg, #318afb, #2a2d34)" bgSize="100% 101vh">
        <Helmet>
          <meta charSet="utf-8" />
          <title>StudioTrackr</title>
          <link rel="canonical" href="https://www.studiotrackr.com/" />
        </Helmet>
        <Hero />
        <Overview />
        <Features />
        <Pricing />
        <CTA />
      </Layout>
      <GdprConsent />
    </>
  );
}

export default IndexPage;
