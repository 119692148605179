import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Img,
  Link,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import screenshotBrowser from "../assets/screenshot_browser.png";
import { BsArrowDown } from "react-icons/bs";
import * as Constants from "../constants";
import SignUpModal from "./SignUpModal";


export default function Hero() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      align="center"
      justify={{ base: "center", lg: "space-between" }}
      direction={{ base: "column-reverse", lg: "row" }}
      wrap="no-wrap"
      minH={{ base: "80vh", md: "87vh" }}
      px={{ base: 0, md: "8vw" }}
      mb={{ base: 16, md: 14, lg: 12, xl: 16 }}
      color="white"
      position="relative"
    >
      <Stack
        spacing={4}
        pl={{ base: 0, xl: "5vw" }}
        mr={{ base: "0", xl: "5" }}
        mt={{ base: "5", lg: "0" }}
        w={{ base: "80%", md: "50%" }}
        align={["center", "flex-start", "flex-start", "flex-start"]}
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="1000"
      >
        <Flex>
          <Heading
            as="h1"
            size="4xl"
            fontWeight="bold"
            textAlign={["center", "center", "left", "left"]}
          >
            Studio
          </Heading>
          <Heading
            as="h1"
            size="4xl"
            fontWeight="normal"
            textAlign={["center", "center", "left", "left"]}
          >
            Trackr
          </Heading>
        </Flex>
        <Heading
          as="h2"
          size="md"
          fontWeight="normal"
          textAlign={["center", "center", "left", "left"]}
          pl={{ base: 0, lg: 1 }}
        >
          Easily manage studio projects without the whiteboards and spreadsheets.
        </Heading>
        <Button
          mt="8"
          size="lg"
          colorScheme="green"
          color="white"
          boxShadow="md"
          as="a"
          href={Constants.AppUrl}
          onClick={() => {
            window.analytics.track("Hero Get Started Clicked", {});
            onOpen();
          }}
        >
          Get Started
        </Button>
      </Stack>
      <Box
        w={{ base: "80%", lg: "50%" }}
        mb={{ base: 12, lg: 0 }}
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="2000"
      >
        <Img src={screenshotBrowser} size="100%" shadow="2xl" />
      </Box>
      <Flex align="center" justify="center" position="absolute" top="95%" w="100%" left="0" right="0" mx="auto">
        <Link href="#overview">
          <Icon
            as={BsArrowDown}
            boxSize="5rem"
            color="white"
          />
        </Link>
      </Flex>
      <SignUpModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
