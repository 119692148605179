import React from "react";
import {
  Box,
  Flex,
  Icon,
  Heading,
  HStack,
  Text,
  VStack,
  Stack,
  Switch,
  Button,
  Link,
  SimpleGrid,
  useDisclosure
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import * as Constants from "../constants";
import SignUpModal from "./SignUpModal";

const Badge = () => {
  return (
    <Flex
      bg="brand.800"
      position="absolute"
      right={-20}
      top={6}
      width="240px"
      transform="rotate(45deg)"
      py={2}
      justifyContent="center"
      alignItems="center"
    >
      <Text
        fontSize="xs"
        textTransform="uppercase"
        fontWeight="bold"
        letterSpacing="wider"
        color="white"
      >
        Popular
      </Text>
    </Flex>
  )
}


const Plan = ({ title, recommended, price, annualBilling, description, features = [], cta, link, transition }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const color = recommended ? "brand.800" : "brand.600"

  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };

  function onClick() {
    window.analytics.track("Plan Clicked", {
      plan: title,
      price: price,
      billingFrequency: annualBilling ? "annual" : "monthly"
    });
    onOpen();
  }

  return (
    <Box
      bg="white"
      position="relative"
      px="6"
      pb="10"
      pt="16"
      overflow="hidden"
      shadow="lg"
      maxW="md"
      width="100%"
      minH={ recommended ? "49vh" : "47vh" }
      // borderRadius={recommended ? "10" : "0"}
      border={recommended ? "2px solid #318afb" : ""}
      zIndex={recommended ? 1 : 0}
      data-sal={transition}
      data-sal-delay="200"
      data-sal-duration="1000"
    >
      {recommended && <Badge />}
      <VStack>
        <Heading
          as="h3"
          size="md"
          fontWeight="bold"
          w="70%"
        >
          {title}
        </Heading>
        <Flex w="100%" justify="center" alignItems="flex-end">
          <Text fontWeight="bold" fontSize="4xl" mr="2">
            ${price}
          </Text>
          <Text fontSize="xl" fontWeight="bold" pb={2}>/ month</Text>
        </Flex>
        <Button
          bg={color}
          w="70%"
          _hover={{ bg: hex2rgba(recommended ? "#318afb" : "#bcc4cc", 0.85) }}
          color={recommended ? "white" : "black"}
          shadow="md"
          // as="a"
          // href={link}
          onClick={onClick}
          style={{ marginTop: 20, marginBottom: 20}}
        >
          {cta}
        </Button>
        <Text w="70%" fontSize="sm" py={2} borderY="2px solid #f2f5f7">
          {description}
        </Text>
        <VStack w="70%" align="flex-start" pt="5">
          {features.map((feature, index) => (
            <Flex align="center" key={index}>
              <Icon as={FaCheckCircle} color={feature.length > 0 ? "brand.800" : "transparent"} mr="2"/>
              <Text fontSize="sm">{feature}</Text>
            </Flex>
          ))}
        </VStack>
        <SignUpModal isOpen={isOpen} onClose={onClose} />
      </VStack>
    </Box>
  )
}


export default function Pricing() {
  const [annualBilling, setAnnualBilling] = React.useState(true);

  return (
    <Flex
      align="center"
      justify="center"
      direction={{ base: "column-reverse", md: "row" }}
      wrap="no-wrap"
      minH={{ base: "80vh", lg: "90vh" }}
      px={{ base: 0, md: "8vw" }}
      bg="brand.600"
      w="100%"
      id="pricing"
      position="relative"
    >
      <VStack spacing="5" pb="10vh" my={{ base: "10vh", xl: 0 }} w="100%">
        <Heading
          as="h2"
          size="lg"
          fontWeight="bold"
          textAlign="center"
          maxW={{ base: "80%", md: "100%" }}
        >
          Pricing Plans
        </Heading>
        <Flex flexDir="column" alignItems="center">
          <Text>Sign up now, upgrade anytime.</Text>
          <Text>All plans are for a single user.</Text>
        </Flex>
        <HStack align="center">
          <Text fontSize="sm">Billed Monthly</Text>
          <Switch
            size="md"
            defaultIsChecked={true}
            isChecked={annualBilling}
            onChange={() => setAnnualBilling(!annualBilling)}
          />
          <Text fontSize="sm">Billed Annually</Text>
        </HStack>
        {/*<Stack direction={["column", "row"]} spacing={["10", "0"]} w="80%" align="center">*/}
        <SimpleGrid
          columns={{ base: 1, lg: 3 }}
          spacing={{ base: '8', lg: '0' }}
          maxW={{ base: "80%", md: "90%", xl: "70%" }}
          mx="auto"
          justifyItems="center"
          alignItems="center"
        >
          <Plan
            title="Free"
            color="#838C96"
            price={annualBilling ? "0" : "0"}
            annualBilling={annualBilling}
            description="For individuals to create and manage a single artist and project."
            cta="Try Now"
            link={Constants.AppUrl}
            transition="slide-right"
            features={[
              "1 artist",
              "1 project",
              "Up to 5 songs",
              "View invited projects",
              "",
              ""
            ]}
          />
          <Plan
            title="Band"
            color="#318afb"
            recommended={true}
            price={annualBilling ? "5" : "6"}
            annualBilling={annualBilling}
            description="For bands who need more projects, songs, and collaborators."
            cta="Sign Up"
            link={Constants.AppUrl}
            transition="slide-up"
            features={[
              "1 artist",
              "Unlimited projects",
              "Unlimited songs",
              "Upload 1 audio file per song",
              "Invite up to 5 collaborators",
              "Invite up to 5 viewers",
              "Collaborate on projects",
            ]}
          />
          <Plan
            title="Studio"
            color="#38A169"
            price={annualBilling ? "30" : "35"}
            annualBilling={annualBilling}
            description="For studios & producers who work with multiple artists."
            cta="Sign Up"
            link={Constants.AppUrl}
            transition="slide-left"
            features={[
              "Unlimited artists",
              "Unlimited projects",
              "Unlimited songs",
              "Upload multiple audio files",
              "Unlimited collaborators",
              "Unlimited viewers",
            ]}
          />
        </SimpleGrid>
        <Text fontSize="sm">*No credit card required for Free plan</Text>
      </VStack>
    </Flex>
  )
}
