import React from "react";
import { Flex, Img, Heading, VStack } from "@chakra-ui/react";
import screenshotMacbook from "../assets/screenshot_macbook2.png";


export default function Overview() {
  return (
    <Flex
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      wrap="no-wrap"
      minH={{ base: "80vh", md: "100vh" }}
      px={{ base: 0, md: "8vw" }}
      bg="brand.600"
      id="overview"
    >
      <VStack spacing="20" pb="10vh" my={{ base: "10vh", md: 0 }}>
        <Flex justify="center" data-sal="slide-up" data-sal-delay="300" data-sal-duration="1000">
          <Heading
            as="h2"
            size="lg"
            fontWeight="bold"
            textAlign="center"
            maxW={{ base: "80%", md: "50%" }}
          >
            Track progress with ease. Quickly see what needs to be done to
            get your music out to listeners.
          </Heading>
        </Flex>
        <img width="80%" src={screenshotMacbook} />
      </VStack>
    </Flex>
  )
}
