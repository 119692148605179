import React from "react";
import { Box, Flex, Img, Text, VStack } from "@chakra-ui/react";
import { withPrefix } from "gatsby"
import screenshotMacbook from "../assets/screenshot_macbook2.png";

const isBrowser = typeof window !== "undefined";


const FeaturePanel = ({ title, description, imageSrc, imagePosition, asteriskSuffix }) => {
  if (isBrowser) {
    imagePosition = window?.innerWidth > 400 ? imagePosition : "right";
  }

  return (
    <Box align="center" display={{ base: "block", md: "flex" }}>
      {imagePosition === "left" &&
        <Box w={{ base: "80%", sm: "60%", md: "50%" }} mt={{ base: "10", md: 0 }}>
          <img size="100%" src={imageSrc} />
        </Box>
      }
      <VStack
        align={["center", "center", "flex-start", "flex-start"]}
        justify="center"
        w={{ base: "80%", md: "50%" }}
        pl={imagePosition === "left" ? "5vw" : 0 }
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="1000"
      >
        <Text
          fontSize="3xl"
          maxW={{ sm: "100%", md: "80%"}}
          fontWeight="600"
          color="white"
        >
          {title}
        </Text>
        <Text
          fontSize="xl"
          maxW={{ sm: "100%", md: imagePosition === "left" ? "90%" : "80%" }}
          color="white"
          textAlign={{ base: "center", md: "left" }}
        >
          {description}
          {asteriskSuffix && <sup>*</sup>}
        </Text>
      </VStack>
      {imagePosition !== "left" &&
        <Box w={{ base: "80%", sm: "60%", md: "50%" }} mt={{ base: "10", md: 0 }}>
          <img size="100%" src={imageSrc} />
        </Box>
      }
    </Box>
  )
}


export default function Features() {
  return (
    <Flex
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      wrap="no-wrap"
      minH={{ base: "90vh", md: "100vh" }}
      px={{ base: 0, md: "8vw" }}
      py="10vh"
      bg="brand.500"
      id="features"
    >
      <VStack spacing="10vh" px={{ base: 0, md: "5vw" }}>
        <FeaturePanel
          title="Manage Tasks"
          description="Track and update progress for each task, by project and song. Easily filter to focus on what matters most."
          imageSrc={screenshotMacbook}
        />
        <FeaturePanel
          title="Real-Time Collaboration"
          description="Share progress with band members, producers, and clients. Discuss changes and feedback for each song."
          imageSrc={screenshotMacbook}
          imagePosition="left"
        />
        <FeaturePanel
          title="Upload Mixes"
          description={"Upload the latest mix to let everyone listen on-the-go. Add multiple revisions to track and compare changes made over time."}
          asteriskSuffix={true}
          imageSrc={screenshotMacbook}
        />
        <Text color="brand.700" fontSize="xs" textAlign="center"><sup>*</sup>All plans can upload tracks. Only the Studio plan can track multiple revisions.</Text>
      </VStack>
    </Flex>
  )
}
