import { Button, Flex, Heading, useDisclosure, VStack } from "@chakra-ui/react";
import React from "react";
import * as Constants from "../constants";
import SignUpModal from "./SignUpModal";


export default function CTA() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      align="center"
      justify="center"
      direction={{ base: "column-reverse", md: "row" }}
      wrap="no-wrap"
      minH={{ base: "20vh", md: "40vh" }}
      w="100%"
      px={{ base: 0, md: "8vw" }}
      py={{ base: 10, md: 5 }}
      color="white"
      bg="#6b6b6b"
      bgImage="url('wavy.svg'),linear-gradient(135deg, #318afb, #2a2d34)"
      bgPosition="50%"
      bgSize="cover"
    >
      <VStack
        spacing="10"
        px={{ base: 0, md: "10vw" }}
        w="100%"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="2000"
      >
        <Heading
          as="h2"
          size="lg"
          fontWeight="bold"
          textAlign="center"
          maxW={{ base: "80%", md: "50%" }}
        >
          Ready to get started?
        </Heading>
        <Flex>
          <Button
            size="lg"
            colorScheme="green"
            color="white"
            boxShadow="md"
            mr="5"
            // as="a"
            // href={Constants.AppUrl}
            onClick={() => {
              window.analytics.track("CTA Get Started Clicked", {});
              onOpen();
            }}
          >
            Get Started
          </Button>
          <Button
            size="lg"
            colorScheme="white"
            border="1px solid white"
            boxShadow="md"
            as="a"
            href="mailto:info@studiotrackr.com"
            onClick={() => window.analytics.track("Get in Touch Clicked", {})}
          >
            Get in Touch
          </Button>
        </Flex>
      </VStack>
      <SignUpModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
